exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicatia-js": () => import("./../../../src/pages/aplicatia.js" /* webpackChunkName: "component---src-pages-aplicatia-js" */),
  "component---src-pages-card-apple-pay-js": () => import("./../../../src/pages/card/apple-pay.js" /* webpackChunkName: "component---src-pages-card-apple-pay-js" */),
  "component---src-pages-card-google-pay-js": () => import("./../../../src/pages/card/google-pay.js" /* webpackChunkName: "component---src-pages-card-google-pay-js" */),
  "component---src-pages-card-js": () => import("./../../../src/pages/card.js" /* webpackChunkName: "component---src-pages-card-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-inchidere-conturi-js": () => import("./../../../src/pages/inchidere-conturi.js" /* webpackChunkName: "component---src-pages-inchidere-conturi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-privacy-policy-js": () => import("./../../../src/pages/mobile/privacy-policy.js" /* webpackChunkName: "component---src-pages-mobile-privacy-policy-js" */),
  "component---src-pages-mobile-support-home-js": () => import("./../../../src/pages/mobile/support/home.js" /* webpackChunkName: "component---src-pages-mobile-support-home-js" */),
  "component---src-pages-promotii-js": () => import("./../../../src/pages/promotii.js" /* webpackChunkName: "component---src-pages-promotii-js" */)
}

